.support-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .ticket-oppened {
        margin-top: 17%;
        h3 {
            font-size: 1.7rem;
            opacity: 0.7;
            font-weight: 500;
        }
    }
    .support {
        margin-top: 3%;
        border-radius: 5px;
        height: 65vh;
        h3 {
            text-align: center;
            font-size: 2rem;
            opacity: 0.7;
        }
        label {
            margin-bottom: 6px;
            font-size: 1rem;
            color: rgba(10, 10, 10, 0.881);
            cursor: text;
        }
        .support-card {
            display: flex;
            flex-direction: column;
            margin: 35px 0px;
            textarea {
                width: 620px;
                height: 220px;
                padding: 15px 20px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
                font-size: 0.95rem;
                resize: none;
            }
        }
        .support-card-flex {
            display: flex;
            justify-content: space-between;
            & > div {
                display: flex;
                flex-direction: column;

                input {
                    width: 300px;
                    height: 40px;
                    border-radius: 3px;
                    border: 0;
                    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
                    padding: 0px 13px;
                    font-size: 0.95rem;
                }
            }
        }
        .support-card-submit {
            input {
                width: 180px;
                height: 40px;
                background-color: var(--sidebar-color);
                border-radius: 4px;
                border: 0;
                font-size: 1rem;
                font-weight: 500;
                color: var(--card-color);
                cursor: pointer;
            }
        }
    }
}