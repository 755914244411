/* Imports SASS modules */
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,400;10..48,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.sidebar, .sidebar--collapse {
  height: 100%;
  width: var(--sidebar-width);
  background-color: var(--sidebar-color);
  color: var(--text-color-white);
  position: fixed;
  top: 0;
  transition: width 0.5s ease-out;
}
.sidebar ul, .sidebar--collapse ul {
  height: auto;
  width: 100%;
  padding: 0;
  list-style: none;
}
.sidebar ul li, .sidebar--collapse ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 60px;
}
.sidebar ul li div, .sidebar--collapse ul li div {
  flex: 30%;
  display: grid;
  place-items: center;
}
.sidebar ul li span, .sidebar--collapse ul li span {
  flex: 70%;
  font-size: 15.686px;
}
.sidebar ul li:hover, .sidebar--collapse ul li:hover {
  cursor: pointer;
  background-color: var(--hover-sb-color);
}
.sidebar ul .sidebar-division, .sidebar--collapse ul .sidebar-division {
  margin: 20px 0px;
  opacity: 0.116;
}
.sidebar--collapse {
  width: var(--sidebar-width-collapsed);
}

.item-selected {
  border-left: 10px solid transparent;
  border-right: 10px solid var(--background-color);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: transparent;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 0;
}

.logo-sidebar, .logo-sidebar--collapse {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--header-height);
  border-bottom: 1px solid rgba(49, 48, 48, 0.219);
  box-shadow: var(--bottom-shadow);
  width: 100%;
  overflow: hidden;
}
.logo-sidebar > div, .logo-sidebar--collapse > div {
  display: flex;
  align-items: center;
  width: 100%;
}
.logo-sidebar > div div, .logo-sidebar--collapse > div div {
  flex: 30%;
  place-items: center;
  display: flex;
  justify-content: center;
  font-size: 20.794px;
  cursor: pointer;
}
.logo-sidebar > div span, .logo-sidebar--collapse > div span {
  flex: 70%;
  font-size: 18.394px;
  font-family: "Palanquin", sans-serif;
}
.logo-sidebar--collapse {
  justify-content: center;
  align-items: center;
}
.logo-sidebar--collapse div {
  margin-left: 0;
}

.content-view, .content-view--collapse {
  height: -moz-fit-content;
  height: fit-content;
  margin-left: var(--sidebar-width);
  transition: margin-left 0.5s ease-out;
}
.content-view .main-view, .content-view--collapse .main-view {
  margin: calc(var(--header-height) + 25px) 25px 25px 25px;
}
.content-view--collapse {
  margin-left: var(--sidebar-width-collapsed);
}

.circle {
  width: 40px;
  height: 40px;
  background-color: var(--brown);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.toggle-sidebar {
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: var(--background-color);
  border: 2px solid rgba(34, 33, 33, 0.616);
  right: -15px;
  top: calc(var(--header-height) - 15px);
}
.toggle-sidebar:hover {
  cursor: pointer;
}

.toggle-icon:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: scale(1.07);
}

.logo {
  width: 50px;
  height: auto;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(22.5% + var(--header-height));
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 6px solid var(--sidebar-color); /* Light grey */
  border-top: 6px solid var(--background-color); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header, .header--collapse {
  height: var(--header-height);
  background-color: var(--header-color);
  border-bottom: 1px solid var(--header-bottom-border-color);
  width: calc(100vw - var(--sidebar-width));
  display: flex;
  align-items: center;
  box-shadow: var(--bottom-shadow);
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: width 0.5s ease-out;
}
.header--collapse {
  width: calc(100vw - var(--sidebar-width-collapsed));
}
.header > div, .header--collapse > div {
  width: 100%;
  margin: 0 3% 0 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header > div div, .header--collapse > div div {
  display: flex;
  align-items: center;
}
.header > div .header-section, .header--collapse > div .header-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 13px;
}
.header > div .header-section:hover, .header--collapse > div .header-section:hover {
  opacity: 0.8;
  transform: scale(1.01);
}
.header > div .header-section div, .header--collapse > div .header-section div {
  margin-right: 6px;
  display: flex;
  align-items: center;
  font-size: 15.686px;
}
.header > div .header-section p, .header--collapse > div .header-section p {
  font-size: 15.686px;
}

.header-title {
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 400;
  font-size: 32.006px;
  color: var(--text-color);
}

.auth-container {
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.auth-container .auth-padlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  color: #fff;
  margin: 8px;
  background-color: #9c27b0;
}
.auth-container > span {
  margin: 0;
  font-weight: 400;
  font-size: 1.5rem;
}
.auth-container form {
  margin-top: 6px;
  width: 400px;
}
.auth-container form .auth-warns {
  margin-top: -0.1%;
}
.auth-container form .auth-warns li {
  margin: 5px 0 0 5px;
  font-size: 0.9rem;
  color: red;
}
.auth-container form > label {
  display: flex;
  margin: 4% 0 7% 0;
  align-items: center;
  cursor: pointer;
}
.auth-container form > label input {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.auth-container form > label span {
  margin-left: 2%;
}
.auth-container form .auth-send {
  width: 100%;
  padding: 6px 18px;
  cursor: pointer;
  vertical-align: middle;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  border: 0;
  border-radius: 4px;
  color: #fff;
  background-color: #1976d2;
}
.auth-container form .auth-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 0.9rem;
  color: rgba(0, 0, 255, 0.55);
  text-decoration: underline;
  cursor: pointer;
}
.auth-container form .auth-footer a:hover {
  color: rgba(0, 0, 255, 0.7);
}

.form-input {
  position: relative;
  margin: 16px 0 8px 0;
  height: 60px;
}
.form-input button {
  position: absolute;
  background-color: transparent !important;
  right: 3%;
  height: 100% !important;
  border: 0;
}
.form-input button span {
  cursor: pointer;
}
.form-input button span :hover {
  opacity: 0.9;
}
.form-input input {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  outline: none;
  border: 1.8px solid rgb(194, 188, 188);
  padding: 1.3rem;
  font-size: 1rem;
  background-color: inherit;
}
.form-input input:focus {
  border: 1.8px solid rgb(71, 59, 236);
}
.form-input input:focus ~ label {
  color: rgb(71, 59, 236);
}
.form-input input:not(:-moz-placeholder-shown) ~ label {
  top: -0.4rem;
  font-size: 0.8rem;
  left: 0.8rem;
  transform: translate(0, 0);
  background-color: var(--background-color);
}
.form-input input:focus ~ label, .form-input input:not(:placeholder-shown) ~ label {
  top: -0.4rem;
  font-size: 0.8rem;
  left: 0.8rem;
  transform: translate(0, 0);
  background-color: var(--background-color);
}
.form-input label {
  position: absolute;
  color: rgba(34, 33, 33, 0.562);
  left: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 0.5rem;
  cursor: text;
  transition: top 100ms ease-in, left 100ms ease-in, font-size 100ms ease-in, transform 100ms ease-in;
}

:root {
  /* Defines color scheme */
  --background-color: #DEDDE0;
  --auth-background-color: #edecf0;
  --card-color: #f1f1f1;
  --sidebar-color: #3B3348;
  --header-color: rgb(230, 225, 225);
  --hover-sb-color: #332d3b;
  --text-color: #363738;
  --text-color-white: #F8F7F9;
  --yellow: #D9AA38;
  --brown: #986E4E;
  --header-bottom-border-color: rgba(73, 72, 72, 0.1);
  /* Defines Layout Variables */
  --header-height: 60px; /* Height of the header  */
  --sidebar-width: 230px;
  --sidebar-width-collapsed: 80px;
  /* Defines global styles */
  --bottom-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
}

.hover:hover {
  cursor: pointer;
  opacity: 0.8;
}

.notifications-wrapper {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000000;
}
.notifications-wrapper div {
  background-color: white;
  min-width: 220px;
  min-height: 45px;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}
.notifications-wrapper div span {
  margin-left: 10%;
}
.notifications-wrapper div:not(:first-child) {
  margin-top: 4%;
}
.notifications-wrapper div:hover {
  cursor: pointer;
}

.dashboard-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: calc(5% + var(--header-height));
}
.dashboard-container .new-report, .dashboard-container .report-history {
  width: 90%;
  background-color: var(--card-color);
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
  padding: 20px;
}
.dashboard-container .new-report {
  height: 170px;
}
.dashboard-container .new-report .new-report-header {
  height: 33%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  font-weight: 600;
}
.dashboard-container .new-report .new-report-content {
  height: 77%;
  margin-top: 15px;
}
.dashboard-container .new-report .new-report-content span {
  font-weight: 500;
}
.dashboard-container .new-report .new-report-content > div {
  margin-top: 7px;
}
.dashboard-container .new-report .new-report-content > div select {
  cursor: pointer;
  background-color: inherit;
  font-size: 0.95rem;
  border: 1px solid rgba(0, 0, 0, 0.21);
  min-width: 210px;
  min-height: 30px;
  padding: 10px 40px 10px 10px;
  border-radius: 4px;
  margin-right: 20px;
}
.dashboard-container .new-report .new-report-content > div button {
  width: 200px;
  height: 40px;
  background-color: var(--sidebar-color);
  border-radius: 4px;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--card-color);
  cursor: pointer;
}
.dashboard-container .report-history {
  margin-top: 3%;
  min-height: 21.4822771214vh;
}
.dashboard-container .report-history .report-history-header {
  height: 4.8335123523vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.dashboard-container .report-history .report-history-header .see-more {
  font-size: 1rem;
  color: #0000FF;
  opacity: 0.7;
  cursor: pointer;
}
.dashboard-container .report-history .report-history-content {
  max-height: 42.9645542428vh;
  overflow-y: scroll;
}
.dashboard-container .report-history .report-history-content > div {
  margin-top: 15px;
  border-radius: 4px;
  height: 75px;
  width: 100%;
  background-color: var(--background-color);
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
}
.dashboard-container .report-history .report-history-content > div div {
  width: -moz-fit-content;
  width: fit-content;
}
.dashboard-container .report-history .report-history-content > div .report-history-data {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.dashboard-container .report-history .report-history-content > div .report-history-data div span {
  font-size: 0.94rem;
}
.dashboard-container .report-history .report-history-content > div .report-history-data div span:first-child {
  margin-right: 10px;
}
.dashboard-container .report-history .report-history-content > div .report-history-data .report-title {
  font-size: 1rem;
  font-weight: 500;
}

.new-report-form {
  display: flex;
  flex-direction: column;
}
.new-report-form .form-group-inline {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.new-report-form .form-group-inline .report-form-group:first-child {
  margin-right: 10px;
}
.new-report-form .form-group-inline .report-form-group:last-child {
  margin-left: 10px;
}
.new-report-form .report-form-group {
  display: flex;
  flex-direction: column;
  margin: 7px 0px 7px 0px;
  width: 100%;
}
.new-report-form .report-form-group label {
  margin: 0px 0px 6px 0px;
  font-weight: 500;
}
.new-report-form .report-form-group input {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  outline: none;
  border: 1.8px solid rgb(194, 188, 188);
  padding: 1.3rem;
  font-size: 1rem;
  background-color: inherit;
}
.new-report-form .report-form-group .select-multiple {
  height: 35px;
  outline: none;
  font-size: 1rem;
}
.new-report-form .report-form-group .select-multiple .ant-select-selector {
  background-color: inherit;
  border-radius: 4px;
  border: 1.8px solid rgb(194, 188, 188);
}

.delete-icon:hover {
  transition: color 0.5s ease-out;
  color: rgb(167, 17, 17);
  transform: scale(1.01);
  cursor: pointer;
  animation-name: rotate;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.modal {
  position: absolute;
  background-color: rgb(236, 233, 233);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  top: calc(var(--header-height) * 1.5);
}
.modal .modalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal .modalContainer .modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal .modalContainer .modalTitle h3 {
  font-size: 1.4rem;
}
.modal .modalContainer .modalTitle button {
  height: 25px;
  width: 25px;
  font-size: 1.5rem;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
}
.modal .modalContainer .modalFooter button {
  padding: 8px 35px;
  border: 0;
  background-color: var(--sidebar-color);
  font-size: 1rem;
  color: var(--card-color);
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}
.modal .modalContainer .modalFooter button:first-child {
  margin-right: 2.5%;
}

.table-section {
  margin-top: 1.7%;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
}
.table-section > div {
  display: flex;
  width: 50%;
}
.table-section .table-section-right {
  align-items: center;
  justify-content: end;
}
.table-section .table-section-right img {
  height: 19.6px;
  width: 19.6px;
  margin: 5px;
}
.table-section .table-section-right > div {
  display: flex;
  height: 40px;
  border: 1px solid rgba(19, 4, 4, 0.534);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.table-section .table-section-right > div:not(:last-child) {
  margin-right: 2%;
}
.table-section .table-section-right .table-setting {
  width: 51.6px;
  background-color: white;
}
.table-section .table-section-right .table-resend {
  width: 145px;
  background-color: white;
  color: #465F70;
  font-size: 16px;
  font-weight: 500;
}
.table-section .table-section-right .table-download {
  width: 104px;
  background-color: #465F70;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.table-section .table-section-left .table-section-header {
  display: flex;
  width: 224px;
}
.table-section .table-section-left .table-section-header-filter {
  align-self: center;
  margin-left: 3.5%;
  width: 108px;
  height: 40px;
  border-radius: 40px;
  background-color: #465F70;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.table-section .table-section-left .table-section-header-filter > * {
  padding: 5px;
}
.table-section .table-section-left .selected-filter {
  margin-left: 1.5%;
  align-self: center;
  align-items: center;
  height: 32px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 10px;
  background-color: white;
  border: 1px solid rgba(19, 4, 4, 0.212);
  border-radius: 16px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  justify-content: space-evenly;
}
.table-section .table-section-left .selected-filter img {
  width: 18px;
  height: 18px;
  margin-left: 2px;
}

.resumo-card {
  background-color: white;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid rgba(19, 4, 4, 0.212);
  border-radius: 7px;
}
.resumo-card .resumo-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  border-bottom: 1px solid rgba(19, 4, 4, 0.116);
  width: 100%;
}
.resumo-card .resumo-card-header span {
  font-size: 1rem;
  font-weight: 700;
}
.resumo-card .resumo-card-header img {
  height: 24px;
  width: auto;
  cursor: pointer;
}
.resumo-card .resumo-card-content, .resumo-card .resumo-card-content--contracted {
  height: 256px;
  overflow: hidden;
  transition: height 0.5s ease-out;
}
.resumo-card .resumo-card-content--contracted {
  height: 0px;
}
.resumo-card .resumo-card-content .resumo-card-content-checkbox, .resumo-card .resumo-card-content--contracted .resumo-card-content-checkbox {
  display: flex;
  padding-left: 15px;
  padding-top: 10px;
}
.resumo-card .resumo-card-content .resumo-card-content-graphs, .resumo-card .resumo-card-content--contracted .resumo-card-content-graphs {
  height: 78%;
  padding-left: 25px;
}
.resumo-card .resumo-card-content .resumo-card-content-graphs > div, .resumo-card .resumo-card-content--contracted .resumo-card-content-graphs > div {
  width: 100%;
  height: 50%;
  display: flex;
}
.resumo-card .resumo-card-content .resumo-card-content-graphs > div:first-child, .resumo-card .resumo-card-content--contracted .resumo-card-content-graphs > div:first-child {
  margin-top: 1.45%;
}
.resumo-card .resumo-card-content .resumo-card-content-graphs > div > div, .resumo-card .resumo-card-content--contracted .resumo-card-content-graphs > div > div {
  width: 33.3333333333%;
}
.resumo-card .resumo-card-content .resumo-card-content-graphs > div > div span, .resumo-card .resumo-card-content--contracted .resumo-card-content-graphs > div > div span {
  font-size: 16px;
  font-weight: 600;
}
.resumo-card .resumo-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 104px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.resumo-card .resumo-card-footer div {
  width: 14.2857143%;
  align-content: left;
  justify-content: left;
  align-self: center;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.resumo-card .resumo-card-footer div .line {
  width: 100%;
  align-self: right;
  height: 1px;
  opacity: 0.8;
}
.resumo-card .resumo-card-footer div span {
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}

.bold {
  font-weight: bold !important;
}

.bar-container {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 90%;
  height: 60%;
}
.bar-container .bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bar-container .val-a {
  background: #004D40;
}
.bar-container .val-b {
  background: #00796B;
}
.bar-container .val-c {
  background: #009688;
}
.bar-container .val-d {
  background: #086e64;
}

.hoverable:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: scale(1.01);
}

.data-table {
  margin-top: 1.3%;
}

.gx-table-responsive {
  table-layout: fixed !important;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  border-collapse: collapse;
}
.gx-table-responsive table {
  width: 100% !important;
}
.gx-table-responsive th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.gx-table-responsive td {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.config-form-item {
  margin-bottom: -5px !important;
}

.scale-element:hover {
  transform: scale(1.01);
  cursor: pointer;
}

.table-text-input {
  width: 100% !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.filter-form {
  width: 100%;
}
.filter-form > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}
.filter-form > div label {
  font-size: 0.95rem;
}
.filter-form > div select {
  width: 60%;
  height: 35px;
  border-radius: 4px;
  outline: none;
  border: 1.8px solid rgb(194, 188, 188);
  font-size: 0.86rem;
  padding-left: 7px;
  background-color: inherit;
  cursor: pointer;
}
.filter-form hr {
  opacity: 0.5;
}

.product-info-modal {
  position: absolute;
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  z-index: 9000;
  top: 35%;
  left: 40%;
}
.product-info-modal > div {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}
.product-info-modal > div button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.product-info-modal img {
  width: 15vw;
  height: auto;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  max-width: 400px;
  min-width: 400px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1000;
  left: 175%;
  margin-left: 0px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltiptext ::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.coming-soon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.coming-soon-text {
  margin-top: 3%;
  width: 100%;
  text-align: center;
}
.coming-soon-text h3 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
}
.coming-soon-text span {
  font-size: 1.3rem;
  opacity: 0.7;
}

.faq-container {
  width: 100%;
  max-width: 70vw;
  margin: 0 auto;
  padding: 15px;
}
.faq-container hr {
  margin: 2.5% 0;
  border: 1px solid rgba(46, 44, 44, 0.11);
}
.faq-container .faq {
  margin: 25px;
  padding: 15px;
  background: #f5f3f3;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}
.faq-container .faq .faq-question {
  position: relative;
  font-size: 19px;
  padding-right: 80px;
  transition: all 0.4s ease;
}
.faq-container .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.faq-container .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}
.faq-container .open .faq-question {
  margin-bottom: 15px;
}
.faq-container .open .faq-question::after {
  content: "-";
  width: 30px;
  height: 30px;
}
.faq-container .open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.support-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.support-container .ticket-oppened {
  margin-top: 17%;
}
.support-container .ticket-oppened h3 {
  font-size: 1.7rem;
  opacity: 0.7;
  font-weight: 500;
}
.support-container .support {
  margin-top: 3%;
  border-radius: 5px;
  height: 65vh;
}
.support-container .support h3 {
  text-align: center;
  font-size: 2rem;
  opacity: 0.7;
}
.support-container .support label {
  margin-bottom: 6px;
  font-size: 1rem;
  color: rgba(10, 10, 10, 0.881);
  cursor: text;
}
.support-container .support .support-card {
  display: flex;
  flex-direction: column;
  margin: 35px 0px;
}
.support-container .support .support-card textarea {
  width: 620px;
  height: 220px;
  padding: 15px 20px;
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  font-size: 0.95rem;
  resize: none;
}
.support-container .support .support-card-flex {
  display: flex;
  justify-content: space-between;
}
.support-container .support .support-card-flex > div {
  display: flex;
  flex-direction: column;
}
.support-container .support .support-card-flex > div input {
  width: 300px;
  height: 40px;
  border-radius: 3px;
  border: 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 13px;
  font-size: 0.95rem;
}
.support-container .support .support-card-submit input {
  width: 180px;
  height: 40px;
  background-color: var(--sidebar-color);
  border-radius: 4px;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--card-color);
  cursor: pointer;
}

.profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: calc(5% + var(--header-height));
}
.profile-container > div {
  width: 55%;
  background-color: var(--card-color);
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
  padding: 20px;
}
.profile-container .profile-container-header {
  height: 200px;
  padding: 20px 40px;
}
.profile-container .profile-container-header > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.profile-container .profile-container-header > div .profile-user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 3px solid rgba(133, 163, 1, 0.7);
  font-size: 1.7rem;
  font-weight: 600;
  color: rgba(133, 163, 1, 0.7);
}
.profile-container .profile-container-header > div .profile-user-data {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}
.profile-container .profile-container-header > div .profile-user-data span {
  margin: 5px 0;
}
.profile-container .profile-container-header > div .profile-user-data span:first-child {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-color);
}
.profile-container .profile-container-header > div .profile-user-data span:last-child {
  color: rgba(6, 6, 189, 0.74);
}
.profile-container .profile-container-content {
  margin-top: 3%;
  height: 350px;
}
.profile-container .profile-container-content > div {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.profile-container .profile-container-content > div .profile-input {
  height: 50px;
}
.profile-container .profile-container-content > div .profile-input label {
  background: var(--card-color);
}
.profile-container .profile-container-content > div button {
  height: 30px;
  background-color: var(--sidebar-color);
  border-radius: 4px;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--card-color);
  cursor: pointer;
}
.profile-container .profile-container-content > div span {
  font-size: 1.3rem;
}

/* Imports Roboto Font from Google Fonts, with all its styles */
/* Defines global CSS variables */
*, *::before, *::after {
  box-sizing: border-box;
  outline: none !important;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}/*# sourceMappingURL=main.css.map */