.dashboard-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: calc(5% + var(--header-height));
    .new-report, .report-history {
        width: 90%;
        background-color: var(--card-color);
        box-shadow: 1px 2px 7px 2px rgba(0,0,0,0.05);
        border-radius: 0.2rem;
        padding: 20px;
    }

    .new-report {
        height: 170px; //170px
        .new-report-header {
            height: 33%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            font-size: 1.1rem;
            font-weight: 600;
        }
        .new-report-content {
            height: 77%;
            margin-top: 15px;
            span {
                font-weight: 500;
            }
            & > div {
                margin-top: 7px;
                select {
                    cursor: pointer;
                    background-color: inherit;
                    font-size: 0.95rem;
                    border: 1px solid rgba(0, 0, 0, 0.21);
                    min-width: 210px;
                    min-height: 30px;
                    padding: 10px 40px 10px 10px; // top right bottom left
                    border-radius: 4px;
                    margin-right: 20px;
                }
                button {
                    width: 200px;
                    height: 40px;
                    background-color: var(--sidebar-color);
                    border-radius: 4px;
                    border: 0;
                    font-size: 1rem;
                    font-weight: 500;
                    color: var(--card-color);
                    cursor: pointer;
                }
            }
        }
    }
    .report-history {
        margin-top: 3%;
        min-height: 21.482277121375vh;
        .report-history-header {
            height: 4.8335123523093vh; //45px
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            font-size: 1.3rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            .see-more {
                font-size: 1rem;
                color: #0000FF;
                opacity: 0.7;
                cursor: pointer;
            }
        }
        .report-history-content {
            max-height: 42.96455424275vh; //400px
            overflow-y: scroll;
            & > div {
                margin-top: 15px;
                border-radius: 4px;
                height: 75px;
                width: 100%;
                background-color: var(--background-color);
                padding: 14px 20px; // top-bottom right-left
                display: flex;
                justify-content: space-between;
                div {
                    width: fit-content;
                }
                .report-history-data {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    div span {
                        font-size: 0.94rem;
                    }
                    div span:first-child {
                        margin-right: 10px;
                    }
                    .report-title {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.new-report-form {
    display: flex;
    flex-direction: column;
    .form-group-inline {
        display: flex;
        flex-direction: row;
        width: 100%;
        .report-form-group:first-child {
            margin-right: 10px;
        }
        .report-form-group:last-child {
            margin-left: 10px;
        }
    }

    .report-form-group {
        display: flex;
        flex-direction: column;
        margin: 7px 0px 7px 0px;
        width: 100%;
        label {
            margin: 0px 0px 6px 0px;
            font-weight: 500;
        }
        input {
            width: 100%;
            height: 35px;
            border-radius: 4px;
            outline: none;
            border: 1.8px solid rgb(194, 188, 188);
            padding: 1.3rem;
            font-size: 1rem;
            background-color: inherit;
        }
        .select-multiple {
            height: 35px;
            outline: none;
            font-size: 1rem;
            .ant-select-selector {
                background-color: inherit;
                border-radius: 4px;
                border: 1.8px solid rgb(194, 188, 188);
            }
        }
    }
}


.delete-icon {
    &:hover {
        transition: color 0.5s ease-out;
        color: rgb(167, 17, 17);
        transform: scale(1.01);
        cursor: pointer;
        animation-name: rotate; 
        animation-duration: 0.8s; 
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes rotate {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(-6deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(6deg);}
    100% {transform: rotate(0deg);}
}