/* Imports SASS modules */
@import './layout/sidebar.scss';
@import './layout/spinner.scss';
@import './layout/header.scss';
@import './layout/login.scss';
@import './themes/themes.scss';
@import './global/global.scss';
@import './global/notifications.scss';
@import './layout/dashboard.scss';
@import './layout/modal.scss';
@import './layout/report';
@import './layout/comingSoon';
@import './layout/faq';
@import './layout/support';
@import './layout/profile';

/* Imports Roboto Font from Google Fonts, with all its styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Defines global CSS variables */
*, *::before, *::after {
  box-sizing: border-box;
  outline: none !important;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}