.profile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: calc(5% + var(--header-height));
    & > div {
        width: 55%;
        background-color: var(--card-color);
        box-shadow: 1px 2px 7px 2px rgba(0,0,0,0.05);
        border-radius: 0.2rem;
        padding: 20px;
    }
    .profile-container-header {
        height: 200px;
        padding: 20px 40px;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            .profile-user-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 125px;
                height: 125px;
                border-radius: 50%;
                border: 3px solid rgb(133, 163, 1, 0.7);
                font-size: 1.7rem;
                font-weight: 600;
                color: rgb(133, 163, 1, 0.7);

            }
            .profile-user-data {
                display: flex;
                flex-direction: column;
                margin-left: 3%;
                span {
                    margin: 5px 0;
                    &:first-child {
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: var(--text-color);
                    }
                    &:last-child {
                        color: rgba(6, 6, 189, 0.74);
                    }
                }
            }
        }
    }
    .profile-container-content {
        margin-top: 3%;
        height: 350px;
        & > div {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            .profile-input {
                height: 50px;
                label {
                    background: var(--card-color);
                }
            }
            button {
                height: 30px;
                background-color: var(--sidebar-color);
                border-radius: 4px;
                border: 0;
                font-size: 1rem;
                font-weight: 500;
                color: var(--card-color);
                cursor: pointer;
            }
            span {
                font-size: 1.3rem;
            }
        }
    }
}