$blue-border : rgb(71, 59, 236);

.auth-container {
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .auth-padlock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    color: #fff;
    margin: 8px;
    background-color: #9c27b0;
  }
  & > span {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
  }
  form {
    margin-top: 6px;
    width: 400px;

    .auth-warns {
      margin-top: -0.1%;
      li {
        margin: 5px 0 0 5px;
        font-size: 0.9rem;
        color: red;
      }
    }

    & > label {
      display: flex;
      margin: 4% 0 7% 0;
      align-items: center;
      cursor: pointer;
      input {
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
      span {
        margin-left: 2%;
      }
    }

    .auth-send {
      width: 100%;
      padding: 6px 18px;
      cursor: pointer;
      vertical-align: middle;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      border: 0;
      border-radius: 4px;
      color: #fff;
      background-color: #1976d2;
    }

    .auth-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      font-size: 0.9rem;
      color: rgba(0, 0, 255, 0.55);
      text-decoration: underline;
      cursor: pointer;
      a {
        &:hover {
          color: rgba(0, 0, 255, 0.7);
        }
      }
    }
  }
}


.form-input {
  position: relative;
  margin: 16px 0 8px 0;
  height: 60px;
  button {
    position: absolute;
    background-color: transparent !important;
    right: 3%;
    height: 100% !important;
    border: 0;
    span {
      cursor: pointer;
      :hover {
        opacity: 0.9;
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
    outline: none;
    border: 1.8px solid rgb(194, 188, 188);
    padding: 1.3rem;
    font-size: 1rem;
    background-color: inherit;
    &:focus {
      border: 1.8px solid $blue-border;
    }
    &:focus ~ label {
      color: $blue-border;
    }
    &:focus ~ label, &:not(:placeholder-shown) ~ label {
      top: -0.4rem;
      font-size: 0.8rem;
      left: 0.8rem;
      transform: translate(0, 0);
      background-color: var(--background-color);
    }
  }
  label {
    position: absolute;
    color: rgba(34, 33, 33, 0.562);
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 0.5rem;
    cursor: text;
    transition: top 100ms ease-in, left 100ms ease-in, font-size 100ms ease-in, transform 100ms ease-in;
  }
}