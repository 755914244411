@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,400;10..48,500&display=swap');

$triangle-height : 8px;

.header {
    height: var(--header-height);
    background-color: var(--header-color);
    border-bottom: 1px solid var(--header-bottom-border-color);
    width: calc(100vw - var(--sidebar-width));
    display: flex;
    align-items: center;
    box-shadow: var(--bottom-shadow);
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: width 0.5s ease-out;
    &--collapse {
        @extend .header;
        width: calc(100vw - var(--sidebar-width-collapsed));
    }
    & > div {
        width: 100%;
        margin: 0 3% 0 3%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;
        }
        .header-section {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0 13px;
            &:hover {
                opacity: 0.8;
                transform: scale(1.01);
            }
            div {
                margin-right: 6px;
                display: flex;
                align-items: center;
                font-size: 15.686px;
            }
            p {
                font-size: 15.686px;
            }
        }
    }
}

.header-title {
    font-family: 'Bricolage Grotesque', sans-serif;
    font-weight: 400;
    font-size: 32.006px;
    color: var(--text-color);
}