.faq-container {
    width: 100%;
    max-width: 70vw;
    margin: 0 auto;
    padding: 15px;
    hr {
        margin: 2.5% 0;
        border: 1px solid rgba(46, 44, 44, 0.11);
    }
    .faq {
        margin: 25px;
        padding: 15px;
        background: #f5f3f3;
        border-radius: 5px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        .faq-question {
            position: relative;
            font-size: 19px;
            padding-right: 80px;
            transition: all 0.4s ease;
            &::after {
                content: "+";
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                transition: all 0.2s ease;
                cursor: pointer;
            }
        }
        .faq-answer {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.2s ease;
        }
    }
    .open {
        .faq-question {
            margin-bottom: 15px;
            &::after {
                content: "-";
                width: 30px;
                height: 30px;
            }
        }
        .faq-answer {
            max-height: 1000px;
            opacity: 1;
        }
    }
}