.notifications-wrapper {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000000;
    div {
        background-color: white;
        min-width: 220px;
        min-height: 45px;
        padding: 10px 20px;
        border-radius: 4px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
        display: flex;
        align-items: center;
        span {
            margin-left: 10%;
        }
        &:not(:first-child) {
            margin-top: 4%;
        }
        &:hover {
            cursor: pointer;
        }
    }
}