.modal {
    position: absolute;
    background-color: rgb(236, 233, 233);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.15);
    padding: 10px 20px;
    top: calc(var(--header-height) * 1.5);
    .modalContainer {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .modalTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
                font-size: 1.4rem;
            }
            button {
                height: 25px;
                width: 25px;
                font-size: 1.5rem;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: inherit;
            }
        }
        .modalFooter {
            button {
                padding: 8px 35px;
                border: 0;
                background-color: var(--sidebar-color);
                font-size: 1rem;
                color: var(--card-color);
                font-weight: 500;
                cursor: pointer;
                border-radius: 4px;
            }
            button:first-child {
                margin-right: 2.5%;
            }
        }
    }
}