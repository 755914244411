@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

.coming-soon {
    width: 100%;
    display: flex;
    justify-content: center;
}

.coming-soon-text {
    margin-top: 3%;
    width: 100%;
    text-align: center;
    h3 {
        font-size: 2.5rem;
        font-weight: 700;
        font-family: 'Roboto Condensed', sans-serif;
    }
    span {
        font-size: 1.3rem;
        opacity: 0.7;
    }
}