.table-section {
    margin-top: 1.7%;
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: space-between;
    & > div {
        display: flex;
        width: 50%;
    }
    .table-section-right {
        align-items: center;
        justify-content: end;
        img {
            height: 19.6px;
            width: 19.6px;
            margin: 5px;
        }
        & > div {
            display: flex;
            height: 40px;
            border: 1px solid rgba(19, 4, 4, 0.534);
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            &:not(:last-child) {
                margin-right: 2%;
            }
        }
        .table-setting {
            width: 51.6px;
            background-color: white;
        }
        
        .table-resend {
            width: 145px;
            background-color: white;
            color: #465F70;
            font-size: 16px;
            font-weight: 500;
        }
        
        .table-download {
            width: 104px;
            background-color: #465F70;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .table-section-left {
        .table-section-header {
            display: flex;
            width: 224px;
        }
        
        .table-section-header-filter {
            align-self: center;
            margin-left: 3.5%;
            width: 108px;
            height: 40px;
            border-radius: 40px;
            background-color: #465F70;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            color: white;
            & > * {
                padding: 5px;
            }
        }

        .selected-filter {
            margin-left: 1.5%;
            align-self: center;
            align-items: center;
            height: 32px;
            width: fit-content;
            padding: 0px 10px;
            background-color: white;
            border: 1px solid rgba(19, 4, 4, 0.212);
            border-radius: 16px;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            justify-content: space-evenly;
            img {
                width: 18px;
                height: 18px;
                margin-left: 2px;
            }
        }
    }
}



.resumo-card {
    background-color: white;
    height: fit-content;
    border: 1px solid rgba(19, 4, 4, 0.212);
    border-radius: 7px;
    .resumo-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        height: 48px;
        border-bottom: 1px solid rgba(19, 4, 4, 0.116);
        width: 100%;
        span {
            font-size: 1rem;
            font-weight: 700;
        }
        img {
            height: 24px;
            width: auto;
            cursor: pointer;
        }
    }
    .resumo-card-content {
        height: 256px;
        overflow: hidden;
        transition: height 0.5s ease-out;
        &--contracted {
            @extend .resumo-card-content;
            height: 0px;
        }
        .resumo-card-content-checkbox {
            display: flex;
            padding-left: 15px;
            padding-top: 10px;
        }
        .resumo-card-content-graphs {
            height: 78%;
            padding-left: 25px;
            & > div {
                width: 100%;
                height: 50%;
                display: flex;
                &:first-child {
                    margin-top: 1.45%;
                }
                & > div {
                    width: calc(100% * (1/3));
                    span {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .resumo-card-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: rgba(0, 0, 0, 0.1);
        height: 104px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        div {
            width: 14.2857143%;
            align-content: left;
            justify-content: left;
            align-self: center;
            text-align: left;
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            .line {
                width: 100%;
                align-self: right;
                height: 1px;
                opacity: 0.8;
            }
            span {
                font-weight: 400;
                padding-top: 8px;
                padding-bottom: 8px;
                font-size: 14px;
            }
        }
    }
}

.bold {
    font-weight: bold !important;
}

.bar-container {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 90%;
    height: 60%;
    .bar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .val-a { background: #004D40 }
    .val-b { background: #00796B }
    .val-c { background: #009688 }
    .val-d { background: #086e64 }
}

.hoverable:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.01);
}

.data-table {
    margin-top: 1.3%;
}

.gx-table-responsive {
    table-layout: fixed !important;
    overflow-x: auto;
    white-space: nowrap;
    display: block;
    border-collapse: collapse;
    table {
        width: 100% !important;
    }
    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
    td {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
}

.config-form-item {
    margin-bottom: -5px !important;
}

.scale-element:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.table-text-input {
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}



.filter-form {
    width: 100%;
    & > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px;
        label {
            font-size: 0.95rem;
        }
        select {
            width: 60%;
            height: 35px;
            border-radius: 4px;
            outline: none;
            border: 1.8px solid rgb(194, 188, 188);
            font-size: 0.86rem;
            padding-left: 7px;
            background-color: inherit;
            cursor: pointer;
        }
    }
    hr {
        opacity: 0.5;
    }
}

.product-info-modal {
    position: absolute;
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index: 9000;
    top: 35%;
    left: 40%;
    & > div {
        display: flex;
        justify-content: space-between;
        padding: 0.5em;
        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
        }
    }
    img {
        width: 15vw;
        height: auto;
    }
}


.tooltip {
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    .tooltiptext {
        visibility: hidden;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px 5px;
        border-radius: 6px;
      
        max-width: 400px; // Adjust this value as needed
        min-width: 400px; // Adjust this value as needed
        word-wrap: break-word; // Ensure long words break into new lines
        white-space: pre-wrap;
        word-break: break-word;
        overflow-wrap: break-word;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1000;
        left: 175%;
        margin-left: 0px;
      
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
        ::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }
    }
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}