.spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(22.5% + var(--header-height));
}

.loading-spinner {
    width: 30px;
    height: 30px;
    border: 6px solid var(--sidebar-color); /* Light grey */
    border-top: 6px solid var(--background-color); /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}