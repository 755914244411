:root {
    /* Defines color scheme */
    --background-color: #DEDDE0;
    --auth-background-color: #edecf0;
    --card-color: #f1f1f1;
    --sidebar-color: #3B3348;
    --header-color: rgb(230, 225, 225);
    --hover-sb-color: #332d3b;
    --text-color: #363738;
    --text-color-white: #F8F7F9;
    --yellow: #D9AA38;
    --brown: #986E4E;
    --header-bottom-border-color: rgba(73, 72, 72, 0.1);
  
    /* Defines Layout Variables */
    --header-height: 60px; /* Height of the header  */
    --sidebar-width: 230px; //
    --sidebar-width-collapsed: 80px; //

    /* Defines global styles */
    --bottom-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
}