$theme-button-w : 5rem;

.sidebar {
    height: 100%;
    width: var(--sidebar-width);
    background-color: var(--sidebar-color);
    color: var(--text-color-white);
    position: fixed;
    top: 0;
    transition: width 0.5s ease-out;
    ul {
        height: auto;
        width: 100%;
        padding: 0;
        list-style: none;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            height: 60px;
            div {
                flex: 30%;
                display: grid;
                place-items: center;
            }
            span {
                flex: 70%;
                font-size: 15.686px;
            }
            &:hover {
                cursor: pointer;
                background-color: var(--hover-sb-color);
            }
        }
        .sidebar-division {
            margin: 20px 0px;
            opacity: 0.116;
        }
    }
    &--collapse {
        @extend .sidebar;
        width: var(--sidebar-width-collapsed);
    }
}

.item-selected {
    border-left: 10px solid transparent;
    border-right: 10px solid var(--background-color);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: transparent;
    width: 0px;
    height: 0px;
    position: absolute;
    right: 0;
}

.logo-sidebar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--header-height);
    border-bottom: 1px solid rgba(49, 48, 48, 0.219);
    box-shadow: var(--bottom-shadow);
    width: 100%;
    overflow: hidden;
    & > div {
        display: flex;
        align-items: center;
        width: 100%;
        div {
            flex: 30%;
            place-items: center;
            display: flex;
            justify-content: center;
            font-size: 20.794px;
            cursor: pointer;
        }
        span {
            flex: 70%;
            font-size: 18.394px;
            font-family: 'Palanquin', sans-serif;
        }
    }
    &--collapse {
        @extend .logo-sidebar;
        justify-content: center;
        align-items: center;
        div {
            margin-left: 0;
        }
    }
}

.content-view {
    height: fit-content;
    margin-left: var(--sidebar-width);
    transition: margin-left 0.5s ease-out;
    .main-view {
        margin: calc(var(--header-height) + 25px) 25px 25px 25px;
    }
    &--collapse {
        @extend .content-view;
        margin-left: var(--sidebar-width-collapsed);
    }
}

$circle-size : 40px;

.circle {
    width: $circle-size;
    height: $circle-size;
    background-color: var(--brown);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc($circle-size / 2);
}

.toggle-sidebar {
    width: calc($circle-size * 0.75);
    height: calc($circle-size * 0.75);
    position: absolute;
    background-color: var(--background-color);
    border: 2px solid rgba(34, 33, 33, 0.616);
    right: calc(-1 * $circle-size * 0.75 / 2);
    top: calc(var(--header-height) - ($circle-size * 0.75)/2);
    &:hover {
        cursor: pointer;
    }
}

.toggle-icon:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.07);
}

.logo {
    width: 50px;
    height: auto;
}